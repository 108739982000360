// Caching and offline logic (from your original sw.js)
(async () => {
  const OFFLINE_VERSION = 5;
  const OFFLINE_URL = "offline.html";
  const CACHE_TIMESTAMP_KEY = "cache-timestamp";

  const response = await fetch('manifest.webmanifest');
  const manifest = await response.json();
  const MANIFEST_VERSION = manifest.version;
  const CACHE_NAME = `offline-${MANIFEST_VERSION}-${OFFLINE_VERSION}`;

  self.addEventListener('install', (event) => {
    event.waitUntil(
      (async () => {
        const cache = await caches.open(CACHE_NAME);
        try {
          await cache.add(new Request(OFFLINE_URL, { cache: 'reload' }));
        } catch (error) {
          console.error('Failed to cache offline.html', error);
        }
        cache.put(CACHE_TIMESTAMP_KEY, new Response(Date.now().toString()));
      })()
    );
  });

  self.addEventListener('activate', (event) => {
    event.waitUntil(
      (async () => {
        if ('navigationPreload' in self.registration) {
          await self.registration.navigationPreload.enable();
        }
        const cacheNames = await caches.keys();
        await Promise.all(
          cacheNames.filter((name) => name.startsWith('offline-') && name !== CACHE_NAME).map((name) => caches.delete(name))
        );
        self.clients.claim();
      })()
    );
  });

  self.addEventListener('fetch', (event) => {
    if (event.request.mode === 'navigate') {
      event.respondWith(
        (async () => {
          try {
            const preloadResponse = await event.preloadResponse;
            if (preloadResponse) {
              return preloadResponse;
            }
            const cache = await caches.open(CACHE_NAME);
            const timestampResponse = await cache.match(CACHE_TIMESTAMP_KEY);
            let cacheTimestamp = timestampResponse ? parseInt(await timestampResponse.text()) : 0;
            let now = Date.now();
            if (now - cacheTimestamp > 0.5 * 24 * 60 * 60 * 1000) {
              await cache.add(new Request(OFFLINE_URL, { cache: 'reload' }));
              await cache.put(CACHE_TIMESTAMP_KEY, new Response(now.toString()));
            }
            const networkResponse = await fetch(event.request);
            return networkResponse;
          } catch (error) {
            console.log('Fetch failed; returning offline page instead.', error);
            const cache = await caches.open(CACHE_NAME);
            return await cache.match(OFFLINE_URL);
          }
        })()
      );
    }
  });

})();

// Ensure the service worker takes control of the page as soon as possible.
self.addEventListener('message', (event) => {
  if (event.data && event.data.type === 'SKIP_WAITING') {
    self.skipWaiting();
  }
});
